import React from 'react'
import put from '../../helpers/put'
import { USER_ENDPOINTS } from '../../services/api.config'

const SaveButton=({handle})=>{
    return (
        <button onClick={handle}  className='mt-2 mb-6 text-primary font-gideonRoman text-sm md:text-base lg:text-lg'>
            Guardar
        </button>
    )
}


const ModalConfig = ({link, data, handleCancelation,updata}) => {
    const preventBubbling=(e)=>e.stopPropagation()
    const handleCreater = ()=>{
        let invitado = []
        let inputData = document.getElementsByClassName("consulta12")
        let inputData2 = document.getElementsByClassName("consulta222")
        let inputPasta = document.getElementsByClassName("consulta13")
        let inputSalsa = document.getElementsByClassName("consulta14")
        var i = 0
        if(inputData){
            for(let el of inputData) {
            var status2 = 1
            if(el.checked){
                status2 = 2
            }
            if(inputData2[i].checked){
                status2 = 3

            }
            console.log(status2)
                if(el.value.length > 0 ){
                    let obj = {
                        id : Number(el.value),
                        status: status2,
                        pasta:Number(inputPasta[i].value),
                        salsa:Number(inputSalsa[i].value)
                    }
                    invitado.push(obj)
                }
                i++
            }
            console.log(invitado)
            let _createClientPromise=put(USER_ENDPOINTS.updateStatus(link),invitado)
            _createClientPromise.then(response=>{
                if(response.message==="all is fine"){
                    handleCancelation()
                }
            })
        }
    }
    return (
        <div className='m-auto w-10/12 md:w-7/12 lg:w-5/12 h-fit bg-white rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-gideonRoman text-base mt-6 text-textColor md:text-lg lg:text-xl'>Confirmación</h3>
                <p className='mx-4 my-2 font-gideonRoman text-sm  text-textColor text-center md:mx-6 lg:mx-8'>Si confirmas tu asistencia a  nuestra noche especial, podrás seleccionar con antelación parte del menú italiano que tenemos preparado para ti (con 3 tipos de salsas y 2 tipos de pasta) Tu  elección será la servida esa noche. </p>
                <div className=" max-h-[50vh] overflow-auto my-3">
                {data?
                    data.map((row,index)=>{
                        return (
                            <div key={index} style={{gridTemplateColumns:'100%', backgroundColor: index % 2 === 0 ? '#fff' : '#f5f5f5'}}  className='grid h-fit py-3' >
                                {[...Object.values(row)].map((cell,index)=>{
                                return <span key={index} className='table-body-cell text-ellipsis text-jn px-3' >
                                            {cell}
                                        </span>
                                })}
                            </div>
                        )
                    })
                :""
                }
                </div>
            </div>
            <p className='mx-4 my-2 font-gideonRoman text-sm  text-textColor text-center '>Los niños no aplican para la selección de comida, ellos tendrán un menu kids.</p>
            <div className='flex justify-center w-9/12 m-auto border-t-[1px] border-textColor border-opacity-50'>
                <SaveButton handle={handleCreater}/>
            </div>
        </div>
    )
}
{/* <br/>Tilda tu nombre <span>si</span> asistirás. */}

export default ModalConfig